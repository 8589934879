
import "../App.css";
import { Loader } from "@react-three/drei"
import Scene from "../Scene";
import SimpleSlide from "../SimpleSlide";

import { useEffect } from "react";
import { CameraTimeline } from "../AnimatedCamera"
import Order from "../Order";
import DisplayOrder from "../DisplayOrder";
import "./home.css";


function getScrollProgress() {
 
  const winScroll = window.document.documentElement.scrollTop;
  const height =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;
  return winScroll / height;
}

function App() {
  // Set the animation to play based on scroll position
  useEffect(() => {
    // register a callback that executes every time the user scrolls
    window.onscroll = (e) => {
      const scrolled = getScrollProgress();
      CameraTimeline.progress(scrolled);

      return () => {
        // We unregister the callback when the component unmounts
        window.onscroll = null;

      };
    };
  }, []);





  return (

    <>
  <Loader 
  containerStyles={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
  dataInterpolation={(p) => `Preparing your table ${p.toFixed(2)}%`}
  />
   
    <div id="article_wrapper">

      {/* HTML slides are nested here and we use vh values to specify where they are */}

      {/* slide1 -TITLE */}
      <SimpleSlide viewportPosition={15}>
        <div id="title">  

        Lan's Cocktail Menu
        
        </div>
        </SimpleSlide>

      {/* slide2 - Signature */}
      <SimpleSlide viewportPosition={120}>

      <div class="menuTitle">

          SIGNATURE!
          <hr  style={{width:"90%" , margin:"1rem 0"}}/>
          </div>

          <div class="menu1"
            style={{ 
            marginLeft:"0" , 
            textAlign:"left",
            alignItems:"left",
            backdropFilter: "blur(5px)"
           }}>
            
            <DisplayOrder width="90%" alignItems="left" textAlign="left" justifyContent="start"  
            displayRange={3} paddingLeft="1rem" />

           <p style={{width:"90%",alignItems:"right", textAlign:"right", justifyContent:"start" ,paddingLeft:"2rem", marginLeft:"2rem", fontWeight:"100", letterSpacing:"2px"}}> 
           
           unit:ml</p>
          </div>
        </SimpleSlide>



        {/* slide3 - Classic-1 */}
        <SimpleSlide viewportPosition={350}>

        <div class="menuTitle-2">

          CLASSIC COCKTAIL 
          <hr />
          </div>
          <br/>


          <div class="menu2"

          style={{ 
          margin:"auto" , 
          textAlign:"center",
          backdropFilter: "blur(5px)", }}>
          
          <DisplayOrder 
          displayRange={2} width="80%" alignItems="center" textAlign="center"  />
          <br/>
          <p style={{width:"90%",alignItems:"center", textAlign:"center", justifyContent:"start" ,paddingLeft:"2rem", marginLeft:"2rem", fontWeight:"100", letterSpacing:"2px"}}> unit:ml</p>
          </div>
        </SimpleSlide>



        {/* slide4 - Classic-2 */}
        <SimpleSlide viewportPosition={520}>          
          <div 
          class="menu3"
          style={{ 
          margin:"auto" , 
          textAlign:"center",
          backdropFilter: "blur(5px)" }}>
          
          <DisplayOrder 
          displayRange={1} width="90%" alignItems="center" textAlign="center" justifyContent="start"  marginLeft="2rem"/>
          <br/>
          <p style={{width:"90%",alignItems:"center", textAlign:"center", justifyContent:"start" ,paddingLeft:"2rem", marginLeft:"2rem", fontWeight:"100", letterSpacing:"2px"}}> unit:ml</p>
          
          </div>
        </SimpleSlide>


        {/* slide5 - Order */}
        <SimpleSlide viewportPosition={750}>
        <div className={"order-section"}>
            
          <Order />

          </div> 
        </SimpleSlide >

      <Scene />

    </div>


    


    </>
  );
}

export default App;



